import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Bot,
  ListingPublishInfo,
  PlatformPublishInfo,
  PlatformPublishStatusEnum,
  SupportedPlatform,
} from 'ignite360-core';
import { isPlatformConnected } from '@/utilities';
import { SOCIAL_PLATFORMS_METADATA } from '@/constants';

@Component
export class PublishMixin extends Vue {
  @Prop({ required: true, type: Object })
  readonly bot!: Bot;

  @Prop({ required: false, type: Object, default: null })
  readonly listingPublishInfo!: ListingPublishInfo;

  @Prop({ required: false, type: String })
  readonly platform!: SupportedPlatform;

  get isConnected() {
    return isPlatformConnected(this.bot, this.platform);
  }
  get isPublished() {
    const platform = this.platform as keyof Exclude<ListingPublishInfo, 'listingId' | 'source'>;

    if (!this.listingPublishInfo || !this.listingPublishInfo[platform]) {
      return false;
    }

    if (
      typeof this.listingPublishInfo[platform] === 'boolean' &&
      this.listingPublishInfo[platform]
    ) {
      return true;
    }

    return (
      (this.listingPublishInfo[platform] as PlatformPublishInfo).status ===
      PlatformPublishStatusEnum.Ok
    );
  }

  get hasError() {
    const platform = this.platform as keyof Exclude<ListingPublishInfo, 'listingId' | 'source'>;
    if (!this.listingPublishInfo || !this.listingPublishInfo[platform]) {
      return false;
    }

    return (
      (this.listingPublishInfo[platform] as PlatformPublishInfo).status ===
      PlatformPublishStatusEnum.Error
    );
  }

  get logo() {
    const platform = (this.platform as string) === 'facebook' ? 'facebookMessenger' : this.platform;
    return SOCIAL_PLATFORMS_METADATA.find((x) => x.value === platform)!.image;
  }
}

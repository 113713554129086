
import { BotMixin } from '@/mixins/BotMixin';
import { SelectOption } from '@/types';
import { CreateManualEntryGenericListing } from 'ignite360-core';
import AnnouncementImageDropzone from '@/components/announcement/AnnouncementImageDropzone.vue';
import OpenHomesInput from './OpenHomesInput.vue';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'listing-manual-entry-form',
  components: { AnnouncementImageDropzone, OpenHomesInput },
})
export default class ListingManualEntryForm extends mixins(BotMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: CreateManualEntryGenericListing;

  @Prop({ required: true, type: String })
  readonly type!: 'create' | 'update';

  get typeSelectOptions(): SelectOption[] {
    return [
      { value: 'buy', label: 'Buy', disabled: false },
      { value: 'rent', label: 'Rent', disabled: false },
      { value: 'sold', label: 'Sold', disabled: false },
    ];
  }

  onInput(key: keyof CreateManualEntryGenericListing, value: any) {
    this.$emit('input', { ...this.value, [key]: value });
  }

  onAddressInput(key: 'displayAddress' | 'suburb', value: any) {
    this.$emit('input', { ...this.value, address: { ...this.value.address, [key]: value } });
  }

  onInspectionsInput(value: any) {
    this.$emit('input', { ...this.value, inspections: [...value] });
  }

  onImageInput(files: { square: File, horizontal: File }) {
    this.$emit('input:image', files);
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';

@Component({
  name: 'datepicker',
  components: { DatePicker },
})
export default class Datepicker extends Vue {
  @Prop({ required: true, type: [String, Date] })
  value!: string | Date;
}

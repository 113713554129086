import { Api, BOTS_BASE_URL } from '@/api/index';
import { getCompressedImage } from '@/utilities';
import axios from 'axios';
import { utcToZonedTime } from 'date-fns-tz';
import {
  GenericListing,
  LoadPaginatedListingsOptions,
  stringifyQueryParams,
  Bot,
  CreateManualEntryGenericListing,
  GenericListingInspection,
} from 'ignite360-core';
import { SocialPostingStrategy } from 'ignite360-core';

export class BotRealEstateApi {
  static async getListings(
    botId: string,
    options?: LoadPaginatedListingsOptions,
  ): Promise<GenericListing[]> {
    const queryParamString = stringifyQueryParams(options || {});

    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/real-estate/listings${
        queryParamString ? `?${queryParamString}` : ''
      }`,
    });

    const result = await axios.request<GenericListing[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not load listings.');
  }

  static async publishPost(
    botId: string,
    data: Pick<GenericListing, 'listingId' | 'source' | 'type'> & {
      strategies?: SocialPostingStrategy[];
    },
  ): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/real-estate/publish-listing`,
      data,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not publish posts for listing.');
  }

  static async addListing(
    botId: string,
    data: CreateManualEntryGenericListing,
    images?: { square: File | null, horizontal: File | null },
  ): Promise<Bot> {
    const formData = new FormData();

    if (data.type) {
      formData.append('type', data.type);
    }
    if (data.headline) {
      formData.append('headline', data.headline);
    }
    if (data.address?.displayAddress) {
      formData.append('address[displayAddress]', data.address.displayAddress);
    }
    if (data.address?.state) {
      formData.append('address[state]', data.address.state);
    }
    if (data.address?.postcode) {
      formData.append('address[postcode]', data.address.postcode);
    }
    if (data.address?.suburb) {
      formData.append('address[suburb]', data.address.suburb);
    }
    if (data.url) {
      formData.append('url', data.url);
    }
    if (data.inspections.length) {
      for (let index = 0; index < data.inspections.length; index++) {
        formData.append(`inspections[]`, JSON.stringify(data.inspections[index]));
      }
    }

    if (images?.square && images.horizontal) {
      const compressedImage = await getCompressedImage(images.horizontal);
      formData.append('imageHorizontal', compressedImage);

      const compressedImageSquare = await getCompressedImage(images.square);
      formData.append('imageSquare', compressedImageSquare);
    }

    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/real-estate/add-listing`,
      data: formData,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not add listing.');
  }

  static async updateListing(
    botId: string,
    listingId: string,
    data: CreateManualEntryGenericListing,
  ): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/real-estate/update-listing/${listingId}`,
      data,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update listing.');
  }

  static async deleteListing(botId: string, listingId: string): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/real-estate/delete-listing/${listingId}`,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not add listing.');
  }

  // Used to convert a GenericListingInspection to CreateManualEntryGenericListing
  static convertGenericListingToCreateManualEntryGenericListing(
    listing: GenericListing,
    timezone: string,
  ): CreateManualEntryGenericListing {
    return {
      headline: listing.headline || '',
      type: listing.type,
      address: listing.address,
      url: listing.url,
      inspections: listing.inspections.map(({ recurrence, opensAt, closesAt }) => {
        return {
          isWeekly: recurrence === 'weekly' ? true : false,
          date: BotRealEstateApi.getDateFromUtcTimestamp(opensAt, timezone),
          opensAt: BotRealEstateApi.getTimeFromUtcTimestamp(opensAt, timezone),
          closesAt: BotRealEstateApi.getTimeFromUtcTimestamp(closesAt, timezone),
        };
      }),
    };
  }

  // Used to convert a GenericListingInspection to CreateGenericListingInspection
  static getTimeFromUtcTimestamp(timestamp: string, timezone: string): string {
    const date = utcToZonedTime(timestamp, timezone);
    const time = date.toLocaleTimeString('en', { timeStyle: 'short', hour12: false });

    return time;
  }

  // Used to convert a GenericListingInspection to CreateGenericListingInspection
  static getDateFromUtcTimestamp(timestamp: string, timezone: string): string {
    const date = utcToZonedTime(timestamp, timezone);
    return date.toISOString().slice(0, 10);
  }
}


import { PlatformPublishInfo } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'social-posting-status',
  components: {},
})
export default class SocialPostingStatus extends Vue {
  @Prop({ required: false, type: Object })
  readonly info!: PlatformPublishInfo;

  @Prop({ required: false, type: Boolean })
  readonly isConnected!: boolean;

  get status() {
    return this.info?.status;
  }
}


import {
  BotDomain,
  GenericListing,
  ListingPublishInfo,
  PlatformPublishInfo,
  PlatformPublishStatusEnum,
  SocialPostingStrategy,
  SupportedPlatform,
  UserRole,
} from 'ignite360-core';
import { Component, Prop } from 'vue-property-decorator';
import { GENERIC_ERROR_DESCRIPTION, SOCIAL_PLATFORMS_METADATA } from '@/constants';
import SocialPostingStatus from '@/components/SocialPostingStatus.vue';
import { mixins } from 'vue-class-component';
import { PublishMixin } from '@/mixins/PublishMixin';
import { BotRealEstateApi } from '@/api/BotRealEstateApi';

@Component({
  name: 'social-posting-modal',
  components: { SocialPostingStatus },
})
export default class SocialPostingModal extends mixins(PublishMixin) {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;
  @Prop({ required: true, type: Object })
  readonly listing!: GenericListing;

  @Prop({ required: false, type: String })
  readonly strategy!: SocialPostingStrategy;

  isSubmitting = false;

  model = this.resetValue();

  get canSend() {
    return this.isConnected && !this.isPublished;
  }

  async onSubmit() {
    try {
      this.isSubmitting = true;

      try {
        const bot = await BotRealEstateApi.publishPost(this.bot.id, {
          listingId: this.listing.listingId,
          source: this.listing.source,
          type: this.listing.type,
          strategies: [this.strategy],
        });

        const listingPublishInfo = bot.domainData[BotDomain.RealEstate]?.publishedListingsList.find(
          (listing) => listing.listingId === this.listing.listingId,
        );
        const platform = this.platform as keyof Exclude<ListingPublishInfo, 'listingId' | 'source'>;
        console.log(listingPublishInfo);
        this.$store.commit('bots/updateBot', bot);

        if (
          listingPublishInfo &&
          (listingPublishInfo[platform] as PlatformPublishInfo).status ===
            PlatformPublishStatusEnum.Ok
        ) {
          this.$emit('published');
          this.$notify.success('Successfully published listing');
        } else {
          throw Error();
        }
      } catch (e) {
        this.$notify.error({
          title: 'Could not post listing',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  onInput(key: string, value: any) {
    this.model = { ...this.model, [key]: value };
  }

  reset() {
    this.model = this.resetValue();
  }
  resetValue() {
    return {
      email: '',
      role: UserRole.Editor,
    };
  }

  get actionButtonLabel() {
    return !this.publishInfo ? 'Publish post' : 'Re-publish post';
  }

  get publishInfo(): PlatformPublishInfo | undefined {
    if (!this.listingPublishInfo) {
      return undefined;
    }
    const platform = this.platform as keyof Exclude<ListingPublishInfo, 'listingId' | 'source'>;
    return (this.listingPublishInfo[platform] as PlatformPublishInfo) || undefined;
  }
}


import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { CreateManualEntryGenericListing, GenericListing } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ListingManualEntryForm from './ListingManualEntryForm.vue';
import { BotRealEstateApi } from '@/api/BotRealEstateApi';

@Component({
  name: 'listing-manual-entry-edit-modal',
  components: { ListingManualEntryForm },
})
export default class ListingManualEntryEditModal extends mixins(BotMixin) {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  @Prop({ required: true, type: Object })
  readonly listing!: GenericListing;

  convertedListing: CreateManualEntryGenericListing = this.resetValue();
  model: CreateManualEntryGenericListing = this.resetValue();

  isSubmitting = false;

  mounted() {
    this.convertedListing = BotRealEstateApi.convertGenericListingToCreateManualEntryGenericListing(
      this.listing,
      this.bot.timezone,
    );
    this.model = JSON.parse(JSON.stringify(this.convertedListing));
  }

  get hasChanges(): boolean {
    return !isEqual(this.model, this.convertedListing);
  }

  onInput(model: CreateManualEntryGenericListing) {
    this.model = { ...model };
  }

  async onSubmit() {
    if (!this.hasChanges) {
      return;
    }
    this.isSubmitting = true;

    try {
      await BotRealEstateApi.updateListing(this.bot.id, this.listing.listingId, this.model);

      this.$emit('updated');
      this.$notify.success('Successfully updated listing');
    } catch (e) {
      this.$notify.error({
        title: 'Could not update listing',
        description: GENERIC_ERROR_DESCRIPTION,
      });
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  private resetValue(): CreateManualEntryGenericListing {
    return {
      headline: '',
      type: 'buy',
      address: {
        displayAddress: '',
        state: '-', // Not needed
        suburb: '', // -> Needed for bot
        postcode: '-', // Not needed
      },
      url: '',
      inspections: [],
    };
  }
}

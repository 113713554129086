
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { PublishMixin } from '@/mixins/PublishMixin';

@Component({
  name: 'real-estate-social-posting',
  components: {},
})
export default class RealEstateSocialPosting extends mixins(PublishMixin) {}


import Timepicker from '@/components/ui/Timepicker.vue';
import Datepicker from '@/components/ui/Datepicker.vue';
import { CreateManualEntryGenericListingInspection } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'open-homes-input',
  components: { Timepicker, Datepicker },
})
export default class OpenHomesInput extends Vue {
  @Prop({ required: false, type: Array })
  readonly value!: CreateManualEntryGenericListingInspection[];

  @Prop({ required: false, type: String, default: '' })
  readonly id!: string;

  addInspection() {
    this.$emit('input', [
      ...this.value,
      {
        isWeekly: false,
        date: '',
        opensAt: '08:00',
        closesAt: '18:00',
      },
    ]);
  }

  removeInspection(index: number) {
    if (this.value[index]) {
      const copiedValue = [...this.value];
      copiedValue.splice(index, 1);
      this.$emit('input', copiedValue);
    }
  }

  onInput(propertyKey: 'date' | 'opensAt' | 'closesAt' | 'isWeekly', index: number, event: string) {
    const copiedValue = [...this.value];
    if (!copiedValue[index]) return;
    if (propertyKey === 'isWeekly') {
      copiedValue[index].isWeekly = !copiedValue[index].isWeekly;
    } else {
      copiedValue[index][propertyKey] = event;
    }
    this.$emit('input', copiedValue);
  }
}

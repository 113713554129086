
import { BotRealEstateApi } from '@/api/BotRealEstateApi';
import { GENERIC_ERROR_DESCRIPTION, REAL_ESTATE_DATA_SOURCES_METADATA } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import {
  BotDomain,
  formatListingAddress,
  formatListingType,
  GenericListing,
  ListingPublishInfo,
  SupportedPlatform,
  ucwords,
} from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ListingManualEntryEditModal from './ListingManualEntryEditModal.vue';
import { SocialPostingStrategy } from 'ignite360-core';
import RealEstateSocialPosting from '@/components/real-estate/RealEstateSocialPosting.vue';
import SocialPostingModal from '@/components/SocialPostingModal.vue';

@Component({
  name: 'real-estate-listings-list-item',
  components: { SocialPostingModal, RealEstateSocialPosting, ListingManualEntryEditModal },
})
export default class RealEstateListingsListItem extends mixins(BotMixin) {
  @Prop({ required: true, type: Object })
  readonly listing!: GenericListing;

  @Prop({ required: true, type: Boolean })
  readonly isFeatured!: boolean;

  @Prop({ required: false, type: Boolean })
  readonly hideFeaturedListingsFeature?: boolean;

  selectedListingPublishInfoPlatform: Pick<
    ListingPublishInfo,
    'facebook' | 'instagram' | 'linkedIn' | 'googleBusiness'
  > = {
    facebook: false,
    instagram: false,
    linkedIn: false,
    googleBusiness: false,
  };

  showPostModal = false;
  showEditModal = false;

  selectedPlatform: SupportedPlatform | null = null;
  selectedStrategy: SocialPostingStrategy | null = null;

  get listingPublishInfo() {
    return (
      this.bot.domainData[BotDomain.RealEstate]?.publishedListingsList.find(
        (listingPublishInfo) => listingPublishInfo.listingId === this.listing.listingId,
      ) || null
    );
  }

  get checkboxId(): string {
    return `listing-${this.listing.source}-${this.listing.listingId}`;
  }

  get headline(): string | undefined {
    return this.listing.headline;
  }

  get addressText(): string {
    return this.listing.address.displayAddress
      ? this.listing.address.displayAddress
      : formatListingAddress(this.listing);
  }

  get sourceText(): string {
    return (
      REAL_ESTATE_DATA_SOURCES_METADATA.find((metadata) => metadata.value === this.listing.source)
        ?.label || ''
    );
  }

  get typeText(): string {
    return ucwords(formatListingType(this.listing.type));
  }

  get typeClass(): string {
    if (this.listing.type === 'buy' || this.listing.type === 'rent') {
      return 'bg-green-100 text-green-800';
    }
    return 'bg-yellow-100 text-yellow-800';
  }

  async onDelete(): Promise<void> {
    try {
      await BotRealEstateApi.deleteListing(this.bot.id, this.listing.listingId);

      this.$emit('deleted');
      this.$notify.success('Successfully deleted listing');
    } catch (e) {
      this.$notify.error({
        title: 'Could not delete listing',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    }
  }

  async openEditModal(evt: Event): Promise<void> {
    evt.preventDefault();
    this.showEditModal = true;
  }

  async onEdit(): Promise<void> {
    this.showEditModal = false;

    // Workaround for now to show changes
    window.location.reload();
  }

  async openPublishPostModal(
    platform: SupportedPlatform,
    strategy: SocialPostingStrategy,
  ): Promise<void> {
    this.selectedPlatform = platform;
    this.selectedStrategy = strategy;
    this.showPostModal = true;
  }
}
